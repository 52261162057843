import './competitive-bid-view.cmp.scss';
import HTML from './competitive-bid-view.cmp.html';
import noop from 'lodash/noop';
import get from 'lodash/get';

import {SvelteDialog} from 'root/v-app/rbServices';
import HotelRfpSpecificationsReportDialog from 'root/rfp-hotel/main/bid/dialogs/HotelRfpSpecificationsReportDialog.svelte';
import CreditCardFormModal from './CreditCardFormModal.svelte';
import {CurrencyFormatter} from 'root/internationalization/internationalization.service';
import {HOTEL_RFP_COMPETITIVE_BIDS_RESPONSE} from 'root/states'
import CompetitiveBidHowItWorksVideoModal from '../CompetitiveBidHowItWorksVideoModal.svelte';

export default {
  template: HTML,
  bindings: {
    loadBidFunction: '<',
    loadBidOptions: '<',
    recipientEmailAddressFn: '<',
    token: '<',
    direct: '<',
  },
  controller: PublicBidPreviewController
}

PublicBidPreviewController.$inject = ['NotificationUtils', 'MainAPI', 'InvitationService', '$state', '$q'];
function PublicBidPreviewController(notificationUtils, mainAPI, Invitation, $state, $q){
  const vm = this;
  let amount, paymentClientSecret, invoiceState;

  vm.viewBidPackage = viewBidPackage;
  vm.handleToPayment = handlePayment;
  vm.handleToResponseForm = handleToResponseForm;
  vm.handleToBidManager = handleToBidManager;
  vm.playVideo = () => {
    SvelteDialog.show(CompetitiveBidHowItWorksVideoModal).catch(noop);
  }

  vm.$onChanges = () => {
    if(vm.loadBidFunction) $onInit()
    vm.isPublic = !!vm.token;
  }

  function $onInit(){
    loadData(notificationUtils.onLoad).catch( noop )
  }

  function loadData(notificationLoaderFunction){
    return notificationLoaderFunction(() => vm.loadBidFunction(), null, null, vm.loadBidOptions)
      .then(parseResponse)
  }

  function parseResponse(res){
    const {bid, invoice, price} = res.data;
    amount = CurrencyFormatter().format(price.amount);
    invoiceState = invoice.state;
    paymentClientSecret = invoice.clientSecret;
    loadBidType(bid);
    vm.bid = bid;
    vm.state = determinePageState(bid, vm.direct);
  }

  function viewBidPackage () {
    SvelteDialog
      .show(HotelRfpSpecificationsReportDialog, {bidId: vm.bid.id, hideTravelDestinationInfo: false})
      .catch(noop);
  }

  function handlePayment(){
    return SvelteDialog.show(CreditCardFormModal, {
        secret: paymentClientSecret,
        askForRecipient: !!vm.token,
        recipient: vm.recipientEmailAddressFn(vm.bid),
        amount,
      }
    )
      .then(() => loadData(notificationUtils.onLoadWithNotification))
      .then(checkInvoiceState)
      .then(handleToResponseForm)
      .catch(noop);
  }

  function checkInvoiceState(){
    if(invoiceState === 'PAID'){
      return notificationUtils.showSuccessDialog({ dialogData: {heading: 'Payment was', message: 'You can now proceed to your Competitive Bid.'}});
    } else {
      notificationUtils.showErrorDialog({dialogData: {message: 'There was an error while processing your payment'}});
      return $q.reject();
    }
  }

  function handleInvitation(){
    return vm.token ? Invitation.handle(vm.token) : Promise.resolve()
  }

  function handleToResponseForm() {
    return handleInvitation()
      .then(() => $state.go(HOTEL_RFP_COMPETITIVE_BIDS_RESPONSE, {bidId: vm.bid.id}), noop)
      .catch( noop );
  }

  function handleToBidManager() {
    handleInvitation()
      .then(() => $state.go('bidManager', {viewId: null}))
      .catch( noop );
  }

  function loadBidType(bid) {
    const subType = get(bid, 'specifications.subType', 'STANDARD');
    vm.isStandardBid = subType === 'STANDARD';
    vm.isRateQuickBid = subType === 'RATE_QUICK';
  }

  function determinePageState(bid, isDirectToPayment) {
    const state = get(bid, 'state.id');
    switch (state) {
      case 'COMPETITIVE_RECEIVED_ACCEPTED':
        return { page: 'DOCUMENT', action: 'RESPOND' };

      case 'COMPETITIVE_SENT':
      case 'COMPETITIVE_RECEIVED':
      case 'COMPETITIVE_DECLINED':
        if(isDirectToPayment) {
          handlePayment()
            .catch(noop);
        }
        return { page: 'DOCUMENT', action: 'PAYMENT' };

      case 'CREATED':
      case 'SENT':
      case 'RECEIVED':
      case 'COMPETITIVE_NEW':
      case 'COMPETITIVE_DELETED':
        return { page: 'NOT_AVAILABLE' };

      case 'COMPETITIVE_REFUSED':
      case 'COMPETITIVE_REFUSED_ACK':
        return { page: 'REFUSED' };

      default:
        return { page: 'DOCUMENT', action: 'BID_MANAGER' };
    }
  }
}
