import {$state as stateProvider} from 'root/angular-injector-provider.js';
import ScrollService from 'root/components/organisms/SideNavigation/ScrollService.js'
import FinalAgreementNotification from 'vRoot/rfp-hotel/final-agreement/emails-notification/FinalAgreementEmailNotificationTutorialNavigationSection.svelte';

export function createMenuItem(label, icon, stateName, currentState, iconAfter = null) {
  const state = stateProvider();
  return {
    label,
    icon,
    iconAfter,
    action: () => state.go(stateName),
    active: stateName === currentState
  };
}

export function createCoverLetter({currentState,  directLetter, chainLetter, label = 'Cover Letter'}) {
  const
    menu = createMenuItem(label, 'description', directLetter.state, currentState, addAttachmentIcon(directLetter.attachments));

  if (chainLetter) {
    menu.active = currentState === directLetter.state || currentState === chainLetter.state;
    menu.submenu = [
      createMenuItem('Direct to Hotel Cover Letter', 'description', directLetter.state, currentState, addAttachmentIcon(directLetter.attachments)),
      createMenuItem('Chain Rep Cover Letter', 'description', chainLetter.state, currentState, addAttachmentIcon(chainLetter.attachments)),
    ];
  }

  return menu;
}

export function createQuestionnaireNavigation(currentState, questionnaireState, refreshMenu, onDestroy){
  const menu = createMenuItem('Questionnaire', 'library_add_check', questionnaireState, currentState);
  if(menu.active) addQuestionnaireSubmenu(menu, refreshMenu, onDestroy);
  return menu;
}

export function createFinalAgreement(currentStateName, stateName, attachments){
  const item = createMenuItem('Final Agreement', 'menu_book', stateName, currentStateName, addAttachmentIcon(attachments));
  if(item.active){
    item.subitem = FinalAgreementNotification;
  }
  return item;
}

function addQuestionnaireSubmenu(qMenu, refreshMenu, onDestroy){
  const submenu = [
    createSubMenuItem('PB', 'Property Basic', 'bookmark'),
    createSubMenuItem('CS', 'Client Specific', 'bookmark'),
    createSubMenuItem('SS', 'Safety and Security', 'bookmark'),
    createSubMenuItem('BD', 'Blackout / Fair Dates', 'bookmark'),
    createSubMenuItem('ES', 'Extended Stay', 'bookmark'),
    createSubMenuItem('GM', 'Group Meetings', 'bookmark'),
    createSubMenuItem('CSR', 'Corporate Social Responsibility', 'bookmark'),
  ];

  let scrollListener;

  const questionnaireReadyEvent = 'QuestionnaireReady';
  const readyListener = function() {
    document.removeEventListener(questionnaireReadyEvent, readyListener, false);
    scrollListener = ScrollService(undefined, submenu, sm => {
      qMenu.submenu = sm;
      refreshMenu();
    });
  };
  onDestroy(() => { if(scrollListener) scrollListener() });
  document.addEventListener(questionnaireReadyEvent, readyListener, false);
}

function createSubMenuItem(id, label, icon){
  return {id, label, icon};
}

function addAttachmentIcon(hasAttachments){
  return hasAttachments ? 'attach_file' : null;
}
