<template>
  <div :class="isSuccess ? $style.itemSuccess : $style.itemFailure">
    <div :class="$style.itemName">{{name}}</div>
    <div :class="$style.itemStatus">
      <div :class="$style.itemStatusText">
        <h1 :class="$style.itemStatusLabel">{{status}}</h1>
        <h2 v-if="at" :class="$style.itemStatusAt">{{at}}</h2>
      </div>
      <div v-if="isSuccess" class="material-icons" :class="$style.successStatusIcon">check_circle</div>
      <div v-else class="material-icons" :class="$style.failureStatusIcon">info</div>
    </div>
  </div>
</template>

<script>

  import {DateTimeFormatter} from "root/internationalization/internationalization.service";

  export default {
    name: 'rbv-hotel-rfp-bid-manager-action-report-item',
    props: {
      report: {
        type: Object,
        required: true
      },
      messages: {
        type: Object,
        required: true
      }
    },
    computed: {
      name () { return this.report.name },
      status () {
        return (this.messages && this.messages[this.report.status]) || this.report.message || this.report.status },
      isSuccess () { return this.report.status === 'OK' },
      at () {
        const formatted = DateTimeFormatter("NULL").format(this.report.at)
        return formatted === "NULL" ? null : formatted
      }
    }
  }
</script>

<style lang="stylus" module="">

  .item {
    height: 41px
    display flex
    border-bottom 1px solid #ECEFF1
    align-items center
    font-size : 13px;
    color : #546E7A;
  }

  .itemSuccess {
    composes item
    background white;
  }

  .itemFailure {
    composes item
    background #F5F6F7;
  }

  .itemName {
    flex 1 3 auto
    min-width 200px
    overflow hidden
    white-space nowrap
    text-overflow ellipsis
    margin-left: 20px;
  }

  .itemStatus {
    flex 3 1 auto
    min-width 200px
    text-align right
    overflow hidden
    white-space nowrap
    text-overflow ellipsis
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .itemStatusText {
    flex 1 0 auto
    line-height 20px
    margin-right 10px;
  }

  .itemStatusLabel {
    font-size : 13px;
    line-height : 16px;
    color : #546E7A;
    font-weight normal
  }

  .itemStatusAt {
    line-height : 12px;
    font-size : 11px;
    color : #90A4AE;
    font-weight normal
  }

  .itemStatusIcon {
    flex 0 0 auto
    font-size 26px;
    height: 26px;
  }

  .successStatusIcon {
    composes itemStatusIcon
    color #00B8AB
  }

  .failureStatusIcon {
    composes itemStatusIcon
    color #78909C
  }

</style>

