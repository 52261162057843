import Moment from 'moment';

export default function formatDate( s = '', format = 'MM/DD/YYYY'){
  const m = Moment(s);
  return m.isValid() ? m.format(format) : s;
}

export function toISO(date = new Date()){
  return date.toISOString().slice(0, 10);
}
