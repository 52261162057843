<Navigation id="{rfpId}" {title} {subtitle} {menu} before="{RfpSpecificationMenuItem}"/>


<script>
  import RfpSpecificationMenuItem from './HotelRfpRfpSpecificationMenuItem.svelte';
  import Navigation from 'root/components/organisms/SideNavigation/SideNavigation.svelte';
  import { createMenuItem, createCoverLetter, createFinalAgreement  } from 'root/components/organisms/SideNavigation/MenuItemFactory.js';
  import { $state as stateProvider } from 'root/angular-injector-provider.js';

  export let rfpId,
    chainSupport = false,
    attachments = {};

  const
    title = 'RFP Details',
    subtitle = 'Manage your RFP';

  const currentState = stateProvider().current.name;
  let menu;

  $: menu = [
    createCoverLetter({
      currentState,
      label: 'RateQuick Letter & Form',
      directLetter: { state: 'hotelRfp.rfp.coverLetter.view', attachments: attachments.direct },
      chainLetter: chainSupport ? { state: 'hotelRfp.rfp.namCoverLetter.view', attachments: attachments.chain } : null
    }),
    createMenuItem('Travel Destinations & Hotels', 'near_me', 'hotelRfp.rfp.destinations.view', currentState),
    createFinalAgreement(currentState, 'hotelRfp.rfp.finalAgreement.view', attachments.finalAgreement),
  ];
</script>
