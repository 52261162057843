import get from 'lodash/get';

export default RfpPreloadController;

RfpPreloadController.$inject = ['NotificationUtils', 'RfpHotelRfpRepository', '$stateParams'];
function RfpPreloadController(utils, rfpHotelRfpRepository, $stateParams){
  const
    vm = this;

  vm.isLoading = () => vm.state === 'LOADING';
  vm.isLoaded = () => vm.state === 'LOADED';
  vm.showStandard = () => vm.isLoaded && isSubtype('STANDARD');
  vm.showOther = () => vm.isLoaded && isSubtype('RATE_QUICK');

  $onInit();

  function $onInit(){
    vm.state = 'LOADING';
    utils.onLoad( () => rfpHotelRfpRepository.getRfpPreview($stateParams.rfpId) )
      .then( httpResponse  => {
        vm.rfp = httpResponse.data;
        vm.state = 'LOADED';
      })
      .catch(() => {
        vm.state = 'ERROR';
      });
  }

  function isSubtype( subType ){
    return get(vm.rfp, 'specifications.subType') === subType;
  }
}
