<template>
  <div :id="template.id" class="questionnaireTableContainer layout-column" :class="template.classes">
    <div class="flex-hold">
      <div class="questionnaireTableHeaderText">{{ template.name }} Table</div>

      <div v-if="template.manageRows && !template.readOnly">
        <div v-for="action in template.manageRows"
             v-if="canAdd(action.id) || canRemove(action.id)"
             class="questionnaireTableActionContainer">

          <button class="questionnaireTableActionButton"
                  :disabled="!canAdd(action.id)"
                  @click="onActionAdd(action.id)">
            <i class="material-icons">add</i>
          </button>
          <div class="questionnaireTableActionLabel">
            {{ action.label }}
          </div>
          <button class="questionnaireTableActionButton"
                  :disabled="!canRemove(action.id)"
                  @click="onActionRemove(action.id)">
            <i class="material-icons">remove</i>
          </button>

        </div>
      </div>

    </div>
    <div class="flex-hold layout-row">
      <div class="questionnaireTableWrapper">
        <table class="questionnaireTable">
          <tr v-for="row in template.cells" :key="row.id"
              v-if="isInFilter(row.for)"

              class="questionnaireTableRow"
              :class="row.classes">

            <template v-for="cell in row.cells" v-if="isInFilter(cell.for)">

              <td v-if="cell.cell" class="questionnaireTableCellQuestion"
                  :class="cell.cell.classes"
                  :rowspan="rowSpan(cell)"
                  :colspan="colSpan(cell)">

                <table-question
                  :template="cell.cell"
                  :model="model"
                  :response="response"
                  :globals="globals"
                  @change="$rbEmit"
                />

              </td>
              <td v-else="" class="questionnaireTableCellLabel"
                  :class="cell.classes"
                  :style="{width: cell.width || 'auto'}"
                  :rowspan="rowSpan(cell)"
                  :colspan="colSpan(cell)"
              >
                {{ cell.name }}
              </td>

            </template>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import { assign } from 'lodash'
  import RbEventsMixin from '../../_mixins/RbEventsMixin.vue'
  import TableQuestion from './QuestionnaireFormTableQuestion.vue'

  export default {
    name: 'questionnaire-form-section-table',

    mixins: [ RbEventsMixin ],

    components: { TableQuestion },

    props: [ 'config', 'globals', 'model', 'template', 'response' ],

    computed: {
      tableActiveFilters () {
        if(this.tableConfig) {
          const state = assign({}, this.tableConfig)
          if(this.tableState) {
            const stateKeys = Object.keys(this.tableState)

            stateKeys.forEach( key => {
              const keyState = this.tableState[key]
              if(keyState.length <= this.tableConfig[key].length){ state[key] = keyState }
            })
          }
          return state
        } else {
          return {}
        }
      },

      tableState () { return this.findByTemplateId( this.response.state ) },
      tableConfig () { return this.findByTemplateId( this.config ) }
    },

    methods: {
      isInFilter ( cellFilters ) {
        return !cellFilters || !Object.keys(cellFilters).find(
          cellFilterKey => this.tableActiveFilters[cellFilterKey].indexOf(cellFilters[cellFilterKey]) === -1
        )
      },

      findByTemplateId ( arr ) {
        const item = arr.find( c => c.id === this.template.id )
        return item ? item.data : undefined
      },

      rowSpan ( cell ) {
        if(cell.rowspanId) {
          return this.tableActiveFilters[cell.rowspanId].length
        } else if (cell.rowspanIds) {
          let span = 1
          cell.rowspanIds.forEach( (id) => { span *= this.tableActiveFilters[id].length })
          return span
        } else {
          return cell.rowspan || 1
        }
      },

      colSpan ( cell ) {
        if(cell.colspanId) {
          return this.tableActiveFilters[cell.colspanId].length
        } else if (cell.colspanIds) {
          let span = 1
          cell.colspanIds.forEach( (id) => { span *= this.tableActiveFilters[id].length })
          return span
        } else {
          return cell.colspan || 1
        }
      },

      onActionAdd (filterId) { this.onAction(filterId, 1) },

      onActionRemove (filterId) { this.onAction(filterId, -1) },

      onAction ( filterId, value ){
        this.$rbEmit({
          name: 'stateChange',
          template: this.template,
          templateFilters: this.template.filters,
          stateId: this.template.id,
          filterId: filterId,
          value: value
        })
      },

      canAdd (filterId) { return this.tableState[filterId].length < this.tableConfig[filterId].length },

      canRemove (filterId) {
        return this.tableState[filterId].length > 1 && this.tableConfig[filterId].length > 1
      }
    }
  }
</script>

<style lang="stylus">

  .questionnaireTableContainer {
    padding: 0 15px;
  }

  .questionnaireTableHeaderText {
    font-size: 13px;
    color: #546e7a;
    padding-left: 10px;
    margin-bottom 20px
  }

  .questionnaireTableActionContainer {
    margin 5px 0 5px 20px
  }

  .questionnaireTableActionLabel {
    width: 80px;
    display: inline-block;
    font-size: 11px;
    color: #546e7a;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align center
  }

  .questionnaireTableActionButton {
    color: #FFFFFF;
    background-color: #00A99D;
    border: solid 1px #00A99D;
    border-radius: 2px;

    cursor: pointer;
    transition: all .2s ease-in-out;
    line-height: 1;
    margin: 0;
    padding: 2px;
    height: 20px;
    width: 20px;

    .material-icons {
      font-size 14px
    }

    &:disabled {
      cursor: default;
      background-color: #ECEFF1 !important;
      color: #90A4AE !important;
      border: 1px solid #DCDEE0 !important;
    }

    &:hover {
      background-color: rgba(0, 169, 157, 0.8);
      color: white;
      border-color: #00A99D;
    }
  }

  .questionnaireTableWrapper {
    border: 1px solid #CFD8DC;
    padding: 3px;
    margin: 12px auto;
  }

  .questionnaireTable {
    table-layout fixed
    border-spacing 0
    border-collapse collapse

    font-size: 11px;
    font-weight: 400;
    background white
    color: #78909C;
    border: 1px solid #CFD8DC
  }

  .questionnaireTableRow {
    height 27px

    &.row-header {
      background-color: #ECEFF1;
      text-align: center;
      font-weight: bold;
      border: 0;
    }

    &.row-sub-header {
      background-color: #FDFDFE;
    }
  }

  .questionnaireTableCellQuestion, .questionnaireTableCellLabel {
    background-color white
    border 1px solid #CFD8DC
    padding 0
  }

  .questionnaireTableCellLabel {
    padding 0 5px

    &.strong {
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  .questionnaireTableRow.row-header .questionnaireTableCellLabel {
    background-color: #ECEFF1;
    text-align: center;
    font-weight: bold;
    border: 0;
  }

  .questionnaireTableRow.row-sub-header .questionnaireTableCellLabel {
    background-color: #FDFDFE;
  }

</style>
