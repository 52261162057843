import { checkAndExecute } from 'root/api/helpers';

export default function (api) {

  api.userAccountReportsSettings = {

    getTmcSummaryReportSettings: () => api.server.get('current-user/reports/settings/tmc-summary-report'),

    setTmcSummaryReportSettings: data => checkAndExecute(
      () => data,
      () => api.server.put('current-user/reports/settings/tmc-summary-report', data),
    ),

  };
}
