export const RATE_LOADING_ALLOWED_ACCOUNTS = [
  '654a758920391e097af8825f', // Haleon
  '6398daef3e041128cdf07833', // TravelINC
  // '5d5b0b819fbe470dc7435b10', // KesselRun Consulting
];

export default function isRateLoadingAvailableToUser(view){
  if(view.side !== 'BUYER') return true;

  return !RATE_LOADING_ALLOWED_ACCOUNTS.length || RATE_LOADING_ALLOWED_ACCOUNTS.includes(view.ownerId);
}
